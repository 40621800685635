<template>
  <div>
    <div id="playlist"></div>
    <button v-on:click="pause">pause</button>
    <button v-on:click="play">play</button>
  </div>
</template>

<script>
import WaveformPlaylist from 'waveform-playlist';

const playListStore = {
  emitter: null
}

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }, 
  methods: {
    play: function (event) {
      window.console.log(event)
      playListStore.emitter.emit("play");
    },
    pause: function (event) {
      window.console.log(event)
      playListStore.emitter.emit("pause");
    }},
  mounted: function (){
    let playlist = WaveformPlaylist({
      samplesPerPixel: 3000,
      mono: true,
      waveHeight: 130,
      container: document.getElementById("playlist"),
      state: "cursor",
      timescale: true,
      colors: {
        waveOutlineColor: "#E0EFF1",
        timeColor: "grey",
        fadeColor: "black"
      },
      controls: {
        show: true,
        width: 200,
        widgets: {
          muteOrSolo: true,
          volume: true,
          stereoPan: true,
          collapse: false,
          remove: false,
        }
      },
      seekStyle : 'line',
      zoomLevels: [500, 1000, 3000, 5000]
    });

    playListStore.emitter = playlist.getEventEmitter();

    let things = this.$store.projectSettings.audioItems.map(x => ({ src: x.url }));
    //console.log(things[0].src)
    playlist
      .load(things)
      .then(function() {
        // can do stuff with the playlist.
        //window.console.log("in then block")
      });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
